<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">



      <v-row>
        <v-col class="mt-0 pt-0 pb-0 xs" cols="12" sm="12" md="12" lg="12">
          <v-card>
            <v-card-actions>
              <v-icon @click="close">mdi-arrow-left-circle</v-icon>
              <v-spacer></v-spacer>
              <v-chip
                class="ma-2"
                :color="vm.status == 'Extracted' ? 'primary' : '#e0e0e0'"
                >{{ vm.status }}</v-chip
              >
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" v-if="vm.status != 'Extracted'"
                >Save</v-btn
              >
              <v-btn @click="extract" v-if="batchItems.length > 0"
                ><v-icon class="mr-2">mdi-cloud-download</v-icon> Extract</v-btn
              >

              <v-progress-circular
                class="ml-2"
              
                :size="40"
                :width="3"
                :rotate="360"
                :value="value"
                color="teal"
              >
                {{ value }}
              </v-progress-circular>
             
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>





      <v-row>
        <v-col class="pt-2 pb-2" cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="vm.name"
                    label="Name"
                    v-validate="'required'"
                    data-vv-as="Name"
                    :readonly="vm.status == 'Extracted'"
                    name="name"
                    :error-messages="errors.collect('name')"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="vm.extractType"
                    :items="['NDIS', 'XERO', 'Generic']"
                    label="Extract type"
                    v-validate="'required'"
                    data-vv-as="Extract type"
                    name="extractType"
                    :error-messages="errors.collect('extractType')"
                  ></v-select>
                </v-col>
              </v-row>



              <v-row>
                <v-col cols="6" class="py-0">
                  <span v-if="vm.createdBy" style="font-size: 0.8em">
                    <span class="font-weight-medium">Created On</span>
                    {{ moment(util.toDt(vm.createdOn)).format("DD-MM-YYYY hh:mm") }}
                    <span class="font-weight-medium"> By</span>
                    {{ vm.createdBy.firstName + " " + vm.createdBy.lastName }}
                  </span>
                </v-col>
                <v-col cols="6" class="py-0">
                  <span v-if="vm.modifiedBy" style="font-size: 0.8em">
                    <span class="font-weight-medium">Modified On</span>
                    {{ moment(util.toDt(vm.modifiedOn)).format("DD-MM-YYYY hh:mm") }}
                    <span class="font-weight-medium"> By</span>
                    {{ vm.modifiedBy.firstName + " " + vm.modifiedBy.lastName }}
                  </span>
                </v-col>
                <v-col cols="6" class="py-0">
                  <span v-if="vm.extractedBy" style="font-size: 0.8em">
                    <span class="font-weight-medium">Extracted On</span>
                    {{ moment(util.toDt(vm.extractedOn)).format("DD-MM-YYYY hh:mm") }}
                    <span class="font-weight-medium"> By</span>
                    {{ vm.extractedBy.firstName + " " + vm.extractedBy.lastName }}
                  </span>
                </v-col>
              </v-row>

              
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0" cols="12">
          <v-card>
            <v-card-actions
              ><v-spacer>Progress notes</v-spacer>

              <v-btn small @click="add" v-if="vm.status != 'Extracted'"
                ><v-icon class="mr-2">mdi-note-plus</v-icon> Add</v-btn
              ></v-card-actions
            >
            <v-card-text>
              <v-data-table
                :items="batchItems"
                :dense="true"
                :items-per-page-options="[50]"
                :footer-props="{ 'items-per-page-options': [-1] }"
               
                :headers="[
                  { text: 'Name', value: 'participantLastname' },
                  { text: 'NDISNumber', value: 'nDISNumber' },
                  { text: 'Support Item Number', value: 'supportItemNumber' },
                  { text: 'Support Item', value: 'supportItem' },
                  { text: 'NDIS Managed', value: 'nDISManaged' },
                  { text: 'Start Date', value: 'startDate' },
                  { text: 'End Date', value: 'endDate' },
                  { text: 'Duration/Quantity', value: 'duration' },
                  { text: 'Unit Price', value: 'unitPrice' },
                  { text: '', value: 'actions' },
                ]"
                class="smallTextTable"
              >
                <template slot="item.participantLastname" slot-scope="props">
                  {{
                    `${props.item.participantFirstname} ${props.item.participantLastname}`
                  }}
                </template>
                <template slot="item.nDISNumber" slot-scope="props">
                  {{ props.item.nDISNumber }}
                </template>
                <template slot="item.SupportItemNumber" slot-scope="props">
                  {{ props.item.SupportItemNumber }}
                </template>
                <template slot="item.SupportItem" slot-scope="props">
                  {{ props.item.SupportItem }}
                </template>
                <template slot="item.nDISManaged" slot-scope="props">
                  {{ props.item.nDISManaged }}
                </template>
                <template slot="item.startDate" slot-scope="props">
                  {{ moment(util.toDt(props.item.startDate)).format("DD-MM-YYYY hh:mm") }}
                </template>
                <template slot="item.endDate" slot-scope="props">
                  {{ moment(util.toDt(props.item.endDate)).format("DD-MM-YYYY hh:mm") }}
                </template>

                <template slot="item.duration" slot-scope="props">
                  {{ util.toFixed(props.item.duration, 2) }}
                  {{ props.item.unitOfMeasure }}
                </template>
                <template slot="item.unitPrice" slot-scope="props">
                  {{ util.toFixed(props.item.unitPrice, 2) }}
                </template>
                <template slot="item.actions" slot-scope="props">
                  <div v-if="vm.status != 'Extracted'">
                    <v-icon
                      slot="activator"
                      small
                      class="mr-2"
                      @click="removeItem(props.item)"
                      >mdi-cart-remove</v-icon
                    >
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <span style="font-size: 0.7em">Batch Id: {{ vm.id }}</span>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import * as migrationService from "../../services/migrationService";
import * as caseService from "../../services/caseService";
import moment from "moment";
import { UserSummary } from "../../models/userSummary";
//import { InvoiceNumber } from "invoice-number";

export default {
  data: () => ({
    vm: { status: "Draft", extractType: "NDIS" },
    batchItems: [],
    totaledResult: [],
    util: null,
    value: 0,
    isExtracted: true,
    abn: "",
  }),
  mounted() {
    this.util = this.$utils;
    this.init();
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentBatch() {
      return this.$store.getters.currentBatch;
    },
  },
  methods: {
    init() {
      if (this.$route.query.id != undefined && this.$route.query.id != null) {
        migrationService
          .getBatchById(this.$store.getters.currentOrganisation.id, this.$route.query.id)
          .then((result) => {
            this.vm = result.data();
            this.$store.commit("setCurrentBatch", this.vm);
            setTimeout(() => {
              this.getBatchItems();
            }, 100);
          });
      } else if (this.currentBatch !== undefined && this.currentBatch !== null) {
        this.vm = this.currentBatch;
        if (this.vm.id != undefined) {
          this.getBatchItems();
        }
      }
    },
    moment: function (date) {
      return date != undefined ? moment(date) : moment();
    },
    close() {
      this.$router.push("/invoicing-billing");
    },
    async add() {
      if (this.util.isEmpty(this.vm.id)) {
        await this.save(false);
      }
      this.$router.push("/invoicing-billing/batch/add-items");
    },
    getBatchItems() {
      const me = this;
      let obj = [];
      migrationService
        .listBatchItems(me.currentOrganisation.id, this.currentBatch.id)
        .then((result) => {
          result.docs.map((doc) => {
            const batchItem = doc.data();
            batchItem.id = doc.id;
            me.batchItems.push(batchItem);
          });

          obj = me.batchItems.map((item) => ({
            ...item,
            abn: me.currentOrganisation.abn, accountcode: me.currentOrganisation.salesAccountCode, taxType:me.currentOrganisation.taxType
          }));
          this.abn = me.currentOrganisation.abn;

          me.batchItems = obj;

          if (me.vm.extractType === "NDIS") {
            this.totalForNDIS(me);
          }
          this.batchItems = me.batchItems.sort(
            me.util.multiFieldSort("+participantLastname", "+supportItem")
          );
        });
        
    },

    totalForNDIS(me) {
      me.batchItems.forEach((x) => {
        x.key =
          x.nDISNumber +
          "-" +
          x.supportItemNumber +
          "-" +
          moment(me.util.toDt(x.endDate)).format("DD-MM-YYYY");
      });

      me.batchItems.reduce((res, value) => {
        //  console.log(res)

        if (!res[value.key]) {
          res[value.key] = {
            key: value.key,
            participantFirstname: value.participantFirstname,
            participantLastname: value.participantLastname,
            providerRegistrationNumber: value.providerRegistrationNumber,
            nDISNumber: value.nDISNumber,
            startDate: value.startDate,
            endDate: value.endDate,
            supportItemNumber: value.supportItemNumber,
            id: value.id,
            quantity: value.quantity,
            unitPrice: value.unitPrice,
            gSTCode: value.gSTCode,
            claimType: value.claimType,
            cancellationReason: value.cancellationReason,
            unitOfMeasure: value.unitOfMeasure,
            totalDuration: 0,
            abn: value.abn,
          };
          me.totaledResult.push(res[value.key]);
        }
        // console.log(me.totaledResult);
        res[value.key].totalDuration += me.util.emptyThen(value.duration, 0);

        return res;
      }, {});
    },
    removeItem(item) {
      const me = this;
      migrationService
        .removeFromBatch(me.currentOrganisation.id, item)
        .then((x) => {
          me.batchItems.splice(me.batchItems.indexOf(item), 1);
          console.log(`Item removed from batch ${x}`);
          me.$store.dispatch("setToast", {
            message: "Item removed from batch",
            color: "primary",
          });
        })
        .catch((err) => {
          this.$store.dispatch("setToast", {
            message: `Error removing item from batch ${err}`,
            color: "red darken-4",
          });
        });
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.save(true);
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4",
          });
        }
      });
    },
    save(redirect) {
      const me = this;
      migrationService
        .saveBatch(me.currentOrganisation.id, me.vm, me.user)
        .then((ref) => {
          if (ref != undefined) {
            me.vm.id = ref.id;
          }
          me.$store.commit("setCurrentBatch", me.vm);
          if (this.vm.status != "Extracted") {
            me.$store.dispatch("setToast", {
              message: "Batch saved",
              color: "primary",
            });
          }
          if (redirect) {
            this.$router.push("/invoicing-billing");
          }
        })
        .catch((error) => {
          console.error("Error saving batch: ", error);
        });
    },
    extract() {
      if (this.vm.extractType === "NDIS") {
        migrationService.extractNDIS(this.totaledResult);
       this.nDISExtract();
      }
      if (this.vm.extractType === "XERO") {
        this.xeroExtract();
      }
      if (this.vm.extractType === "Generic") {
        this.genericExtract();
      }
      this.vm.status = "Extracted";
      this.vm.extractedOn = new Date();
      this.vm.extractedBy = Object.assign({}, new UserSummary(this.user));

      this.save(false);
    },

    async xeroExtract() {
      let me = this;
      var data = [];
      const dt = new Date();
     
      for (let i = 0; i < me.batchItems.length; i++) {
        me.value = Math.ceil(((i + 1) / me.batchItems.length) * 100);
        let x = me.batchItems[i];
        
        let caseData = null;

        await caseService
          .getById(this.currentOrganisation.id, x.participantId)
          .then((doc) => {
            caseData = doc.data();
          });
        let ps = null;

        ps = caseData.signatures;
        
        data.push({
          "*ContactName": `${x.participantFirstname} ${x.participantLastname}`,
          "EmailAddress": `${
            this.$utils.isEmpty(ps) ? "" : this.$utils.emptyThen(ps.email, "")
          }`,
          POAddressLine1: "",
          POAddressLine2: "",
          POAddressLine3: "",
          POAddressLine4: "",
          POCity: "",
          PORegion: "",
          POPostalCode: "",
          POCountry: "Australia",
          "*InvoiceNumber": x.invoiceNumber,
          Reference: x.progressNoteId,
          "*InvoiceDate": moment(dt).format("DD-MM-YYYY"),
          "*DueDate": moment(dt).add(14, "days").format("DD-MM-YYYY"),
          InventoryItemCode: `${this.$utils.emptyThen( x.supportItemNumber, "")}`,
          "*Description":`${this.$utils.emptyThen(x.supportItem, "")}
          NDIS Number:${x.nDISNumber}
          Work Date:${moment(this.$utils.toDt(x.startDate)).format("DD-MM-YYYY")}
          Start Time: ${moment(this.$utils.toDt(x.startDate)).format("HH:mm ")}
          End Time: ${moment(this.$utils.toDt(x.endDate)).format("HH:mm ")}`,
          "*Quantity": this.$utils.emptyThen(Math.floor(x.duration*100)/100, 0),
          "*UnitAmount": x.unitPrice,
          Discount: "",
          "*AccountCode": x.accountcode,
          "*TaxType": x.taxType,
          TrackingName1: "",
          TrackingOption1: "",
          TrackingName2: "",
          TrackingOption2: "",
          Currency: "",
          BrandingTheme: "",
        
        });
      }
      migrationService.extractXero(data);
    },

    async nDISExtract(){

      let me = this;
      var data = [];
      const dt = new Date();
      for (let i = 0; i < me.batchItems.length; i++) {
        me.value = Math.ceil(((i + 1) / me.batchItems.length) * 100);
        let x = me.batchItems[i];
        let caseData = null;

        await caseService
          .getById(this.currentOrganisation.id, x.participantId)
          .then((doc) => {
            caseData = doc.data();
          });
        let ps = null;

        ps = caseData.signatures;
        
        
        data.push({
          "*ContactName": `${x.participantFirstname} ${x.participantLastname}`,
           "EmailAddress": `${
            this.$utils.isEmpty(ps) ? "" : this.$utils.emptyThen(ps.email, "")
          }`,
          POAddressLine1: "",
          POAddressLine2: "",
          POAddressLine3: "",
          POAddressLine4: "",
          POCity: "",
          PORegion: "",
          POPostalCode: "",
          POCountry: "Australia",
          "*InvoiceNumber": x.invoiceNumber,
          Reference: x.progressNoteId,
          "*InvoiceDate": moment(dt).format("DD-MM-YYYY"),
          "*DueDate": moment(dt).add(14, "days").format("DD-MM-YYYY"),
          InventoryItemCode: `${this.$utils.emptyThen( x.supportItemNumber, "")}`,
          "*Description":`${this.$utils.emptyThen(x.supportItem, "")}
          NDIS Number:${x.nDISNumber}
          Work Date:${moment(this.$utils.toDt(x.startDate)).format("DD-MM-YYYY")}
          Start Time: ${moment(this.$utils.toDt(x.startDate)).format("HH:mm ")}
          End Time: ${moment(this.$utils.toDt(x.endDate)).format("HH:mm ")}`,
          "*Quantity": this.$utils.emptyThen(Math.floor(x.duration*100)/100, 0),
          "*UnitAmount": x.unitPrice,
          Discount: "",
          "*AccountCode": x.accountcode,
          "*TaxType": x.taxType,
          TrackingName1: "",
          TrackingOption1: "",
          TrackingName2: "",
          TrackingOption2: "",
          Currency: "",
          BrandingTheme: "",
        
        });
      }
      migrationService.extractNdis(data);
      
    },

    async genericExtract() {
      let me = this;
      var data = [];

      for (let i = 0; i < me.batchItems.length; i++) {
        me.value = Math.ceil(((i + 1) / me.batchItems.length) * 100);
        let x = me.batchItems[i];
        let caseData = null;

        await caseService
          .getById(this.currentOrganisation.id, x.participantId)
          .then((doc) => {
            caseData = doc.data();
          });
        let pd = null;

        pd = caseData.participantDetails;
        let row = {
          LastName: this.$utils.isEmpty(pd) ? "" : this.$utils.emptyThen(pd.lastname, ""),
          FirstName: this.$utils.isEmpty(pd)
            ? ""
            : this.$utils.emptyThen(pd.firstname, ""),
          EmailAddress: this.$utils.isEmpty(pd)
            ? ""
            : this.$utils.emptyThen(pd.email, ""),
          InvoiceNumber: this.$utils.emptyThen(x.id, ""),
          CRNNumber: this.$utils.isEmpty(pd)
            ? ""
            : this.$utils.emptyThen(pd.cRNNumber, ""),
          NDISNumber: this.$utils.isEmpty(pd)
            ? ""
            : this.$utils.emptyThen(pd.nDISNumber, ""),
          SWSNumber: this.$utils.isEmpty(pd)
            ? ""
            : this.$utils.emptyThen(pd.sWSNumber, ""),
          TaxFileNumber: this.$utils.isEmpty(pd)
            ? ""
            : this.$utils.emptyThen(pd.taxFileNumber, ""),
          Description: `${this.$utils.emptyThen(
            x.supportItemNumber,
            ""
          )} - ${this.$utils.emptyThen(x.supportItem, "")}`,
          supportItemNumber: this.$utils.emptyThen(x.supportItemNumber, ""),
          StartDate: this.$utils.toDt(x.startDate).toISOString(),
          EndDate: this.$utils.toDt(x.endDate).toISOString(),
          NDISManaged: x.nDISManaged,
          ClaimType: x.claimType,
          CancellationReason: this.$utils.emptyThen(x.cancellationReason, ""),
          ContactType: x.contactType,
          GstType: x.gstType,
          Duration: x.duration,
          Price: x.unitPrice,
          Notes: x.notes,
          Field1: this.$utils.isEmpty(caseData.additionalData)
            ? ""
            : this.$utils.emptyThen(caseData.additionalData.field1, ""),
          Field2: this.$utils.isEmpty(caseData.additionalData)
            ? ""
            : this.$utils.emptyThen(caseData.additionalData.field2, ""),
          Field3: this.$utils.isEmpty(caseData.additionalData)
            ? ""
            : this.$utils.emptyThen(caseData.additionalData.field3, ""),
          Field4: this.$utils.isEmpty(caseData.additionalData)
            ? ""
            : this.$utils.emptyThen(caseData.additionalData.field4, ""),
          Field5: this.$utils.isEmpty(caseData.additionalData)
            ? ""
            : this.$utils.emptyThen(caseData.additionalData.field5, ""),
          Field6: this.$utils.isEmpty(caseData.additionalData)
            ? ""
            : this.$utils.emptyThen(caseData.additionalData.field6, ""),
          Field7: this.$utils.isEmpty(caseData.additionalData)
            ? ""
            : this.$utils.emptyThen(caseData.additionalData.field7, ""),
          Field8: this.$utils.isEmpty(caseData.additionalData)
            ? ""
            : this.$utils.emptyThen(caseData.additionalData.field8, ""),
          Field9: this.$utils.isEmpty(caseData.additionalData)
            ? ""
            : this.$utils.emptyThen(caseData.additionalData.field9, ""),
          Field10: this.$utils.isEmpty(caseData.additionalData)
            ? ""
            : this.$utils.emptyThen(caseData.additionalData.field10, ""),
        };

        data.push(row);
      }

      migrationService.extractGeneric(data);
    },
  },
};
</script>
<style></style>
